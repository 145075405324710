<template>
  <div
    v-if="agentTimings.timings"
    class="agent-timings"
  >
    <b-row
      v-for="(item, index) of agentTimings.timings"
      :key="index"
    >
      <b-col
        cols="12"
        md="2"
        class="d-flex align-items-center"
      >
        <b-form-checkbox
          v-model="item.agent_enabled"
          :disabled="readonly"
          :name="item.day"
          switch
          inline
        >
          <span>{{ item.day }}</span>
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group :label="$t('Starts at')">
          <b-form-timepicker
            v-model="item.start_time"
            :disabled="readonly"
            locale="en"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group :label="$t('Ends at')">
          <b-form-timepicker
            v-model="item.end_time"
            :disabled="readonly"
            locale="en"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormCheckbox, BFormTimepicker } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormTimepicker,
  },
  props: {
    agentTimings: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
