<template>
  <component :is="userData === undefined ? 'div' : 'b-card'" class="user-profile">
    <agent-timings :readonly="true" :agent-timings="agentTimings" />
  </component>
</template>

<script>
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useProviderUi } from './useProvider'
import { isEmpty } from '@/utilities'
import AgentTimings from './shared/AgentTimings.vue'

export default {
  components: {
    AgentTimings,
  },
  data() {
    return {
      agentTimings: {
        timings: [],
      },
      userData: {},
    }
  },
  computed: {
    isAgent() {
      return window.SuperApp.getters.isAgent()
    },
  },
  mounted() {
    const { providerProfile, getProviderAgentProfile } = useProviderUi()
    const callType = this.isAgent ? getProviderAgentProfile : providerProfile
    showLoader()
    callType()
      .then(({ data }) => {
        if (data.statusCode === '200') {
          this.userData = data.responseData
          const dayKeys = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
          setTimeout(() => {
            if (this.isAgent) {
              this.agentTimings = {
                timings: dayKeys.map(x => ({
                  day: x,
                  end_time: this.getStoreTIme(x, 'end_time'),
                  start_time: this.getStoreTIme(x, 'start_time'),
                  agent_enabled: this.isTimeEnabled('ALL') ? true : this.isTimeEnabled(x),
                })),
              }
            }
          }, 250)
          hideLoader()
        }
      })
      .catch(error => {
        console.log(error, 'error')
        showErrorNotification(this, error)
      })
  },
  methods: {
    getStoreTIme(key, tag) {
      return this.userData.timings && this.userData.timings.find(x => x.day === key) ? this.userData.timings.find(x => x.day === key)[tag] : '00:00'
    },
    isTimeEnabled(key) {
      return this.userData.timings && Boolean(this.userData.timings.find(x => x.day === key))
    },
    updateTimings(){

    }
  },
}
</script>

<style>
.user-profile-info ul.nav.nav-pills {
  justify-content: center;
}

.profile-img-height {
  height: 16px;
}
@media only screen and (max-width: 500px) {
  .profile-img-height {
    width: 24px !important;
    height: 24px !important;
  }
}
</style>
